@import '../../../styles/mixins';
@import '../../../styles/brandColours';

.title {
  font-size: 1.875rem;
  font-weight: 700;
}

.booking_guest_details {
  width: 100%;
  padding-bottom: 2rem;

  input, select {
    width: 100%;
    height: 4.33rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0.3rem 0.6rem #0000001c;
    border: 1px solid $apollo-primary;
    border-radius: 0.5rem;
    opacity: 1;
    margin-top: 0.5rem;
    padding-left: 1rem;
    font-size: 0.8em;
  }

  label {
    display: block;
    font-size: 1.5rem;
    margin-top: 2rem;
    font: 350 16px/24px Mont;
  }
}

.register_guest_details_button {
  margin-top: 1rem;
  height: 52px;
  line-height: 52px;
  width: 100%;
  background: $apollo-primary 0% 0% no-repeat padding-box;
  border: 1px solid $apollo-primary;
  border-radius: 0.5rem;
  text-align: center;
  font: 700 1.4rem Mont;
  color: #ffffff;
}