@import '../../styles/brandColours';

.success-email-sent {
  border-radius: 5px;
  .MuiSnackbarContent-root {
    background-color: green;
    font-size: 1em;
  }
}

.fail-email-sent {
  border-radius: 5px;
  .MuiSnackbarContent-root {
    background-color: red;
    font-size: 1em;
  }
}

.email-send-btn-wrapper {
  position: relative;
  &--loading {
    position: absolute;
    width: 30px;
    top: 45%;
    left: 45%;
    svg {
      color: $apollo-primary;
    }
  }
}

.emailed-quote-notice {
  margin: 3em;
  margin-top: 1em;
  text-align: center;

  .subheader-text {
    font-size: 0.8em;
  }

  .quote-number {
    font-weight: bold;
    font-size: 2em;
  }
}