@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/brandColours';

.page-content{
  width:95%;
  max-width: 1200px;
  margin:2rem auto;
  @include sm{
    margin:auto;
    width:100%;
  }
}

.validation-text {
  font-size: 0.8em;
  font-style: italic;
}

.booking-guest-details {
  width: 100%;
  padding: 2rem;
  margin-top: 3rem;
  > form > div,
  > div {
    margin-top: 2rem;
  }
  input[type='text'], select {
    width: 90%;
    height: 4.33rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0.3rem 0.6rem #0000001c;
    border: 1px solid $apollo-primary;
    border-radius: 0.5rem;
    opacity: 1;
    margin-top: 0.5rem;
    padding-left: 1rem;
    @include sm {
      width: 100%;
    }

    &.invalid {
      border: 2px solid red;
    }
  }

  // select {
  //   width: 15.4rem;
  // }

  label {
    display: block;
    font-size: 1.5rem;
    margin-top: 2rem;
    font: 350 16px/24px Mont;
  }

  .enter-details-below {
    font: 700 2.1rem/3.1rem Mont;
  }

  .astrics {
    color: #d50000;
  }
  .selected-cover{
    color:$apollo-primary;
  }
  .mobile-landline-label {
    font: 400 13px/19px Mont;
    display: inline;
    margin-left: 1rem;
  }

  .mobile-landline-radio {
    margin-left: 1rem;
  }

  .phone-number-label {
    display: inline;
  }

  .phone-number-text {
    display: block;
  }
  .phone-number-container {
    margin-top: 2rem;
  }
  .phone-number-option {
    margin-top: 2rem;
    @include xs {
      margin-top: 0;
    }
    input,
    label {
      vertical-align: middle;
    }
  }
}

.email-quote-contact-details {
  @extend .booking-guest-details;
  margin-top: 0px;
  padding-top: 0px;
  div {
    margin-top: 0;
    padding: 0px 3px;
    input[type='text'],
    select {
      width: 100%;
    }
  }
  .validation-message {
    font-size: 0.8em;
    color: red;
    @include xs {
      margin-top: 5px;
      text-align: left;
    }
  }
  .email-send-btn-wrapper--loading {
    padding: 0px;
  }
  &--btn {
    input {
      width: 100%;
    }
  }
}

.banner {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
  background: transparent linear-gradient(280deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%) 0% 0% no-repeat padding-box;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 15px !important;

  @include xs {
    text-align: center;
  }
}

@mixin themable($partner-code, $color, $background) {
  .partner_#{$partner-code} {
    .banner {
      background: $background 0% 0% no-repeat padding-box;
      color: $color;
    }
  }
}

@include themable(WWCCMHCO, white, $cheapa-secondary);
@include themable(WWCCMHNZ, white, $cheapa-secondary);
@include themable(WWCCMHCA, white, $cheapa-secondary);

@include themable(WWHIPCOM, $hippie-primary, $hippie-primary);
@include themable(WWNZHIP, $hippie-primary, $hippie-primary);

@include themable(STARRV, $default-text-color, $default-text-color);

@include themable(WWBUNK, white, $cheapa-secondary);

@include themable(RACQAFF, $racx-secondary, $racx-primary);
@include themable(RACV, $racx-secondary, $racx-primary);
