@import '../../styles/brandColours';

.customised-stepper {
  &__step {
    flex: 1 1 0;
  }
  &__title {
    text-align: center;
    font: 400 1.4rem Mont;
    margin-bottom: 1rem;
  }
  &__circle {
    width: 2rem;
    height: 2rem;
    margin: auto;
    border-radius: 50%;
    border: solid 0.1rem;
    text-align: center;
    line-height: 2rem;
  }
  &__line {
    height: 0.1rem;
    position: absolute;
    right: calc(50% + 1rem);
    left: calc(-50% + 1rem);
    top: 3.7rem;
  }
  &--completed {
    background: $apollo-primary;
    border: 0.1rem solid $apollo-primary;
    color: white;
  }
  &--uncompleted {
    font: 400 1.4rem Mont;
    color: #adadad;
    line-height: 2rem;
  }
  &--current {
    border: 1px solid $apollo-primary;
  }

  &--line-completed {
    background-color: $apollo-primary;
  }

  &--line-uncompleted {
    background-color: #adadad;
  }
  &__inner-circle {
    fill: $apollo-primary;
  }
  &__tick {
    font-size: 1.6rem !important;
  }
}
