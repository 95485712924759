@import '../../styles/variables';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/brandColours';
@import './UpdateSearch';

.search-results {
  max-width: 125rem;
  margin: auto;
  &__label {
    text-align: center;
    font-weight: 600;
    @include sm {
      margin-top: 10px;
    }
  }
  &__logos {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.8em;
    @include sm {
      justify-content: center;
    }
  }
  &__logo {
    height: 100%;
    margin-left: 2rem;
    border-radius: 5px;
    &:hover {
      box-shadow: 1px 4px 4px #eaeaea;
      cursor: pointer;
    }
    @include sm {
      box-shadow: 1px 4px 4px #eaeaea;
    }
    .logo-brand {
      &__img {
        height: 50px;
        padding: 5px;
        border-radius: 5px;
      }
    }
  }
  &__top-logo {
    height: 100%;
  }
  &__filters {
    padding-right: 2rem;
    @include sm {
      padding-right: 0;
    }
    @include xs {
      padding: 0 0 2rem 0;
    }
  }
  padding: 12rem 2rem;
  @include sm {
    padding: 0 2rem;
  }
}

.collapse-update-search {
  margin: 2rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  overflow: hidden;
  .update-search {
    position: relative;
    padding-top: 0px;
    background:none;
  }
  .MuiExpansionPanelSummary-content{
    margin:2rem 0;
  }
  .MuiExpansionPanel-rounded{
    background: $header-container-background 0 0 no-repeat padding-box;
  }
  .MuiExpansionPanelDetails-root {
    padding: 0px;
  }
  .MuiTypography-root {
    font: 700 1.8rem/2.2rem Mont;
    color: $header-container-color;
  }
  .MuiAccordion-root {
    background: $header-container-background 0 0 no-repeat padding-box;
  }
}

.promoted-specials-header {
  border: 2px solid $apollo-primary;
  background-color: scale-color($color: $apollo-primary, $lightness: 95%);
  text-align: center;
  padding: 1em;

  h4 {
    margin: 0px;
  }
}

@mixin themable($vendor-code, $color) {
  .#{$vendor-code}.promoted-specials-header {
    border: 2px solid $color;
    background-color: scale-color($color: $color, $lightness: 95%);
  }
}

@include themable(CCAU, $cheapa-primary);
@include themable(CCNZ, $cheapa-primary);
@include themable(CCUS, $cheapa-primary);

@include themable(HCAU, $hippie-primary);
@include themable(HCNZ, $hippie-primary);

@include themable(APAU, $apollo-primary);
@include themable(APDE, $apollo-primary);
@include themable(APFR, $apollo-primary);
@include themable(APNZ, $apollo-primary);
@include themable(APUK, $apollo-primary);
@include themable(APIE, $apollo-primary);
@include themable(APUS, $apollo-primary);

@include themable(FFAU, $apollo-primary);
@include themable(ZEAU, $apollo-primary);
@include themable(FFNZ, $apollo-primary);
@include themable(ZENZ, $apollo-primary);
@include themable(FFUS, $apollo-primary);
@include themable(ZEUS, $apollo-primary);

@include themable(SRAU, $default-text-color);
@include themable(SRNZ, $default-text-color);
@include themable(SRUS, $default-text-color);

@include themable(BKGB, $cheapa-primary);
@include themable(BKIE, $cheapa-primary);
