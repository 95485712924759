@import '../../styles/brandColours';

.important-infos {
  [class='important-info'] {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
  a{
    color:$apollo-primary;
    &:hover,
    &:visited{
      color:$apollo-primary;
    }
  }

  .important-info {
    margin-bottom: 20px;
  }
}
