@import '../../styles/brandColours';

.booking-summary-item {
  text-align: left;
  padding-top: 5px;
  margin-bottom: 10px;
  line-height: 1.3;

  &__description {
    display: inline-block;
    vertical-align: middle;
    width: 70%;
    font: 400 1rem Mont;
    padding-left: 7px;

    .description {
      font-weight: bold;
    }
  }
  &__value {
    width: 30%;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    font: 700 1.2rem Mont;
    color: $apollo-secondary;
  }
  &__subvalue {
    width: 30%;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    font: 700 1.2rem Mont;
    color: $apollo-secondary;
    font-size: 0.7em;
  }
}

.currency {
  font: 700 0.8rem Mont;
}

@mixin partner-style($partner-code, $price-value-color) {
  .partner_#{$partner-code} {
    .booking-summary-item {
      &__value {
        color: $price-value-color;
      }
      &__subvalue {
        color: $price-value-color;
      }
    }
  }
}

@include partner-style(WWAPMHCO, $apollo-secondary);

@include partner-style(WWCCMHCO, $cheapa-secondary);
@include partner-style(WWCCMHNZ, $cheapa-secondary);
@include partner-style(WWCCMHCA, $cheapa-secondary);

@include partner-style(WWHIPCOM, $hippie-primary);
@include partner-style(WWNZHIP, $hippie-primary);

@include partner-style(STARRV, $star-secondary);

@include partner-style(WWBUNK, $bunk-secondary);

@include partner-style(RACQAFF, $racx-secondary);
@include partner-style(RACV, $racx-secondary);