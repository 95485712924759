@import '../../styles/brandColours';

.booking-payment-option {
  border: 1px solid #d3d3d3;
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 2rem;
  padding: 2rem;
  font-weight: 600;

  input[type='radio'] {
    min-width: 0;
    width: 1.5rem;
    height: 1.5rem;
    background: none;
    box-shadow: none;
    border: none;
    margin-top: -1rem;
    padding-left: 0;
  }
  &__full-pay-note {
    margin-top: 15px;
  }
  &__note {
    color: $apollo-primary;
  }
  &__body {
    padding-top: 1rem;
  }
  &__radio {
    text-align: right;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
    color: $apollo-primary;
  }

  &__item-amount {
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    font: 700 1.4rem Mont;
    color: $apollo-secondary;
  }

  &__group {
    padding-top: 1.5rem;
    border-top: grey 1px dashed;
  }
  &__item {
    padding-top: 0.2rem;
    font-size: 1.4rem;
  }

  &__option-upsell-list, &__option-payLater-list {
    margin-left: 0;
    margin-bottom: 0;
  }

  &__option-upsell, &__option-payLater {
    list-style: none;
    margin-bottom: 0.5em;
  }
}

@mixin partner-style($partner-code, $header-color, $price-color) {
  .partner_#{$partner-code} {
    .booking-payment-option {
      &__title {
        color: $header-color;
      }

      &__note {
        color: $header-color;
      }

      &__item-amount {
        color: $price-color;
      }    
    }    
  }
}

@include partner-style(WWAPMHCO, $apollo-primary, $apollo-secondary);

@include partner-style(WWCCMHCO, $cheapa-primary, $cheapa-secondary);
@include partner-style(WWCCMHNZ, $cheapa-primary, $cheapa-secondary);
@include partner-style(WWCCMHCA, $cheapa-primary, $cheapa-secondary);

@include partner-style(WWHIPCOM, $hippie-secondary, $hippie-secondary);
@include partner-style(WWNZHIP, $hippie-secondary, $hippie-secondary);

@include partner-style(STARRV, $star-primary, $star-secondary);

@include partner-style(WWBUNK, $bunk-primary, $bunk-secondary);

@include partner-style(RACQAFF, $racx-secondary, $racx-secondary);
@include partner-style(RACV, $racx-secondary, $racx-secondary);