@mixin clearfix {
  content: '';
  display: table;
  clear: both;
}
$min-width:360px;
$xxs-width: 400px;
$xs-width: 600px;
$sm-width: 960px;

@mixin min {
  @media (max-width: #{$min-width}) {
    @content;
  }
}

@mixin xxs {
  @media (max-width: #{$xxs-width}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$xs-width + 1px}) {
    @content;
  }
}
