@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Black Italic.otf');
  font-style: italic;
  font-weight: 900;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Black.otf');
  font-weight: 900;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Bold Italic.otf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Bold.otf');
  font-weight: 700;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Book Italic.otf');
  font-style: italic;
  font-weight: 350;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Book.otf');
  font-weight: 350;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont ExtraLight Italic.otf');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont ExtraLight.otf');
  font-weight: 200;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Hairline Italic.otf');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Hairline.otf');
  font-weight: 100;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Heavy Italic.otf');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Heavy.otf');
  font-weight: 900;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Light Italic.otf');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Light.otf');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Regular Italic.otf');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Regular.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont SemiBold Italic.otf');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont SemiBold.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Thin Italic.otf');
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: 'Mont';
  src: url('../assets/fonts/Fontfabric - Mont Thin.otf');
  font-weight: 100;
}
