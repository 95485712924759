@import '../../styles/mixins';
@import '../../styles/brandColours';

.page-content{
  width:95%;
  max-width: 1200px;
  margin:2rem auto;
  @include sm{
    margin:auto;
    width:100%;
  }
}

.booking-payment {
  > div {
    margin-top: 2rem;
  }
  padding: 2rem;
  margin-top: 3rem;
  width: 100%;
  &__title {
    font: 700 2.1rem/3.1rem Mont;
  }

  label {
    display: block;
    font-size: 1.5rem;
    margin-top: 2rem;
    font: 350 16px/24px Mont;
  }

  &__payment_text {
    font-style: italic;
    padding-top: 1rem;
    font-size: 1.4rem;
  }
  .selected-cover {
    color: $apollo-primary;
  }

  input[type='text'] {
    width: 90%;
    height: 4.33rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0.3rem 0.6rem #0000001c;
    border: 1px solid $apollo-primary;
    border-radius: 0.5rem;
    opacity: 1;
    margin-top: 0.5rem;
    padding-left: 1rem;
    @include sm {
      width: 100%;
    }
  }
}
