@import '../../styles/mixins';
@import '../../styles/brandColours';

.pickup-dropoff {
  &__container {
    padding-left: 2rem;
    width: 100%;
    position: relative;
    @include xs {
      padding-left: 1rem;
      border: solid 1px;
      border-radius: 5px 5px 5px 5px;
      background-color: #ffffff;
    }
  }

  &--small {
    font: 500 1.1rem/1.4rem Mont;
    padding-top: 1rem;
  }

  &--big {
    font: 700 1.3rem/1.7rem Mont;
    padding-bottom: 1rem;
    white-space: nowrap;
    @include xxs {
      font-size: 1.1rem;
    }
    @include min {
      font-size: 0.9rem;
    }
  }

  &--changed-from {
    text-decoration: line-through;
    font-weight: lighter;
  }

  &--text-white {
    color: #ffffff;
    white-space: nowrap;
  }

  &--dark-bg {
    background: $star-primary 0% 0% no-repeat padding-box;
  }

  &__chevron {
    width: 4rem;
    height: 100%;
    fill: white;
    background-color: $star-primary;
  }
  &__vehicle-image {
    position: absolute;
    height: 20rem;
    top: 7rem;
    bottom: 0;
    right: 0.5rem;
  }
  &__edit {
    height: 5rem;
    width: 100%;
    background: transparent
      linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%) 0% 0% no-repeat
      padding-box;
    border: none;
    text-align: center;
    font: 600 1.6rem Mont;
    color: #ffffff;
    cursor: pointer;

    @include xs {
      margin-top: 0.5rem;
      border-radius: 5px 5px 5px 5px;
    }
  }
}
