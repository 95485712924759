@import '../../styles/mixins';
@import '../../styles/brandColours';

.suggested_deals_banner_container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  height: 100%;
  margin-bottom: 1em !important;

  color: white;
  background: $apollo-secondary;

  font-size: 14px;
  font-weight: 600;
  padding: 0.8em 0.5em 0.5em 0.5em;
  border-radius: 5px;
}

.hotdeals_header {
  text-transform: uppercase;
  text-align: center;
  line-height: 0.9;

  font-weight: 900;

  @include md {
    font-size: 3.5em;
  }
}

.hotdeals_hot {
  @include xs {
    font-size: 4em;
  }
}

.hotdeals_deals {
  @include xs {
    font-size: 2.5em;    
  }
}

.blurb_container {
  border-left: solid 2px white;
  margin: 5px 0px;
  padding-left: 20px;

  @include xs {
    text-align: center;
    padding-left: 10px;
  }
}

.dates_flexible {
  font-weight: bold;

  @include md {
    line-height: 1;
    font-size: 1.3em;
  }

  @include xs {
    font-size: 0.8em;
  }
}

.savings_blurb {
  color: $apollo-primary;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1;

  @include md {
    font-size: 3em;
  }

  @include xs {
    span {
      display: block;
    }

    .savings_blurb_line1 {
      font-size: 1.7em;
    }

    .savings_blurb_line2 {
      font-size: 2.4em;
    }
  }
}

.action_button {
  background-color: white;
  color: $apollo-secondary;
  border-radius: 10px;
  text-transform: uppercase;
  line-height: 1;
  padding-top: 5px;
  padding-bottom: 3px;
  font-weight: 700;

  @include md {
    display: inline;
    padding-left: 15px;
    padding-right: 15px;
  }

  @include xs {
    max-width: 140px;
    margin: 0 auto;
  }
}
