@import '../../styles/brandColours';

@mixin themable($vendor-code, $color) {
  .specs-link-#{$vendor-code} {
    font: 600 1.6rem/2.4rem Mont;
    color: $color;
    padding-right: 1.5rem;
    fill: $color;
    align-self: center;

    a {
      color: $color;
      text-decoration: none;
    }
  }
}

@include themable(CCAU, $cheapa-primary);
@include themable(CCNZ, $cheapa-primary);
@include themable(CCUS, $cheapa-primary);

@include themable(HCAU, $hippie-primary);
@include themable(HCNZ, $hippie-primary);

@include themable(APAU, $apollo-secondary);
@include themable(APDE, $apollo-secondary);
@include themable(APFR, $apollo-secondary);
@include themable(APNZ, $apollo-secondary);
@include themable(APUK, $apollo-secondary);
@include themable(APIE, $apollo-secondary);
@include themable(APUS, $apollo-secondary);

@include themable(FFAU, $apollo-secondary);
@include themable(ZEAU, $apollo-secondary);
@include themable(FFNZ, $apollo-secondary);
@include themable(ZENZ, $apollo-secondary);
@include themable(FFUS, $apollo-secondary);
@include themable(ZEUS, $apollo-secondary);

@include themable(SRAU, $star-primary);
@include themable(SRNZ, $star-primary);
@include themable(SRUS, $star-primary);

@include themable(BKGB, $cheapa-primary);
@include themable(BKIE, $cheapa-primary);
