@import '../../styles/mixins';
@import '../../styles/brandColours';

.update-search {
  background: $header-container-background 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 8.4rem;
  padding: 2rem;
  margin: auto;
  position: fixed;
  z-index: 1;

  &__update {
    height: 52px;
    line-height: 52px;
    width: 100%;
    background: transparent
      linear-gradient(99deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 0px 0.3rem 0.6rem #00000029;
    border-radius: 0.5rem;
    text-align: center;
    font: 600 1.6rem Mont;
    color: #ffffff;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  border: none;
  width: 100%;
  font-size: 1.2rem;
  &:focus {
    outline: 0;
  }
}

body {
  touch-action: manipulation; // Remove Tap to Zoom
  font-family: 'Mont', sans-serif;
}

.select-apollo__title {
  font-size: 11px!important;
  color: grey!important;
  font-family: 'Mont', sans-serif!important;
}

// .react-datepicker-popper {
//   position: fixed!important;
// }

.react-datepicker {
  z-index: 100;
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.MuiTypography-body1 {
  font-size: 1.38rem!important;
  font-weight: 700!important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.5rem !important;
}

.MuiTypography-body2 {
  font-size: 1.30rem!important;
}

.apollo-calender .PrivatePickersFadeTransitionGroup-root.MuiCalendarPicker-viewTransitionContainer .MuiTypography-caption {
  font-size: 1.2rem;
  font-weight: bold;
  color: orange;
}

.select-apollo__date > * {
  border: none!important;
  font-family: Mont!important;
  font-size: 1.2rem!important;
  padding-left: 0.5rem;
}

.datepicker-ap {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0.5rem;
  align-items: center;
}

.select-apollo {
  min-height: 5.2rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &__title {
    font: 1.3rem Mont SemiBold;
  }

  &__img {
    margin-right: 10px;
    height: fit-content;
    img {
      vertical-align: middle;
    }
  }

  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  align-items: center;
  padding: 0.5rem;

  &__options {
    border: none;
    font-family: Mont;
    font-size: 1.2rem;
    border-radius: 5px;
    width: 90%;
    height: 100%;
    &:focus {
      outline: 0;
    }
    @include xs {
      width: 100%;
    }
  }
}
