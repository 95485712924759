@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/brandColours';

$height: 6.3rem;
.result-count {
  font: 350 14px/20px Mont;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@mixin themable($vendor-code, $color, $background) {
  .search-result-group--#{$vendor-code} {
    &:not(:first-child) {
      margin-top: 1rem;
    }
    .brand-header {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 0.5rem;
      background: $background 0% 0% no-repeat padding-box;
      &__right {
        &__join {
          @include xs {
            font: 400 1.3rem/1.8rem Mont;
            max-width: 11rem;
          }
          @include md {
            font: 400 1.7rem/2.3rem Mont;
          }
          color: #ffffff;
          display: inline-block;
          vertical-align: middle;
        }
        &__club {
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 0.5rem;
          width: 8.2rem;
          height: 4rem;
          font: 700 1.6rem/1.4rem Mont;
          color: $apollo-primary-gradient-end;
          display: inline-block;
          margin-left: 1rem;
          padding: 0.6rem;
          margin-right: 1rem;
          vertical-align: middle;
        }
      }
      &__left {
        padding: 0.5rem 1rem;
        width: 100%;
        &__logo {
          display: inline-block;
          vertical-align: middle;
          height:40px;
        }
        height: 100%;
        &__count {
          font: 350 1.4rem/2rem Mont;
          color: #ffffff;
        }
        .logo-brand {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          &__img {
            @include xs {
              text-align: center;
              margin-bottom:5px;
            }
          }
          &__banner {
            color: white;
            font-size: 14px;
            font-weight: 600;
            padding: 5px;
            border-radius: 5px;
            @include xs {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .#{$vendor-code} {
    &.unavailable-search-result-banner {
      border: 0.2rem solid $color;
      border-radius: 0.5rem;
      margin: 0.5em 0;
      padding: 0.5em;
      color: #ffffff;
      background: $background;
      cursor: pointer;
      width: 100%;
      line-height: normal;
      font-size: 0.9em;

      .header {
        margin-bottom: 0.5em;
        font-weight: bold;
        font-size: 1.1em;
      }

      svg {
        font-size: 4em;
        margin-top: 4px;
        margin-left: 5px;
      }
    }
  }
}

@include themable(CCAU, $cheapa-primary, $cheapa-primary);
@include themable(CCNZ, $cheapa-primary, $cheapa-primary);
@include themable(CCUS, $cheapa-primary, $cheapa-primary);

@include themable(HCAU, $hippie-primary, $hippie-primary);
@include themable(HCNZ, $hippie-primary, $hippie-primary);

@include themable(APAU, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(APDE, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(APFR, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(APNZ, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(APUK, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(APIE, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(APUS, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));

@include themable(FFAU, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(ZEAU, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(FFNZ, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(ZENZ, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(FFUS, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));
@include themable(ZEUS, $apollo-primary, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));

@include themable(SRAU, $default-text-color, $default-text-color);
@include themable(SRNZ, $default-text-color, $default-text-color);
@include themable(SRUS, $default-text-color, $default-text-color);

@include themable(BKGB, $cheapa-primary, $cheapa-primary);
@include themable(BKIE, $cheapa-primary, $cheapa-primary);
