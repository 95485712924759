@import '../../styles/mixins';
@import '../../styles/brandColours';

.cover-extras-tabs {
  &__header {
    cursor: pointer;
    display: inline-block;
    height: 4.2rem;
    line-height: 4.2rem;
    @include md {
      width: 18.1rem;
    }
    @include xs {
      width: 100%;
      margin-bottom: 1rem;
    }
    border: 0.1rem solid $apollo-secondary;
    @include md {
      border-bottom: none;
    }
    @include xs {
      border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
    @include md {
      border-radius: 0.5rem 0.5rem 0 0;
    }
    text-align: center;
    margin-right: 2rem;
    font: 700 1.4rem/4.2rem Mont;
    color: $apollo-secondary;

    &--selected {
      cursor: default;

      @include md {
        background: $apollo-secondary 0% 0% no-repeat padding-box;
        color: #ffffff;
      }
    }
  }
  
  &__content {
    border: 0.1rem solid $apollo-secondary;
    border-radius: 0px 0.5rem 0.5rem 0.5rem;
    margin-bottom: 2rem;
    &--hidden {
      display: none;
    }

    .booking-details-section {
      padding: 2.5rem;
      border-bottom: 0.1rem solid $apollo-secondary;

      &__name {
        font: 700 1.5rem/2rem Mont;
        cursor: pointer;
      }

      &__toggle {
        font: Bold 1.7rem/5.2rem Mont;
        color: $apollo-secondary;
        padding: 1rem;
      }
    
      &--hidden {
        display: none !important;
      }
    }

    .booking-details-section:last-child {
      border: none;
    }
  }

  &__info-wrapper {
    height: 30px;
  }

  &__info {
    margin-bottom: 0.5rem;

    .MuiTooltip-tooltip {
      background: $apollo-secondary;
      font-size: 1rem;

      @include xs {
        width: 250px;
      }
      @include xxs {
        width: 200px;
      }
    }

    .MuiTooltip-arrow::before{
      color: $apollo-secondary;
    }

    .MuiButton-root {
      min-width: 0px;
      width: min-content;
      padding: 0px;
      border-radius: 100%;

      .MuiButton-label {
        width: auto;
        svg {
          width: 2rem;
          height: 2rem;
          color: $apollo-secondary;
        }
      }
    }
  }
}

.cover {
  font: 400 1.2rem/1.8rem Mont;
  &__item {
    padding: 2rem;
  }
  
  &__name {
    font: 600 1.7rem/2.4rem Mont;
    color: $apollo-secondary;
    padding-bottom: 2rem;
  }

  &__liability-amount {
    font-weight: 700;
  }

  &__liability-refundable {
    margin-top: 0.5rem;
  }
  
  &__due-text {
    color: $apollo-primary;
  }

  &__dailyprice {
    font-weight: 700;
    color: $apollo-secondary;
  }

  &__capped {
    font-size: 1.1rem;
    text-align: right;
  }
  
  &__inclusions {
    font: 600 1.6rem/2.3rem Mont;
    color: $apollo-secondary;
    padding-bottom: 2rem;
    &--list {
      display: flex;
      align-items: baseline;
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 1rem;
    }
    &--list-check {
      margin-right: 5px;
      .MuiSvgIcon-root {
        width: 2.1rem;
        height: 2.1rem;
        color: $apollo-secondary;
        vertical-align: middle;
      }
    }
  }
  &__description {
    font-size: 1.5rem;
    line-height: 2rem;
    padding-bottom: 2rem;
  }
  &__seperator {
    border-bottom: 0.1rem solid #d3d3d3;
    padding-top: 2rem;
  }
  &__select {
    background: transparent linear-gradient(280deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 0 0.3rem 0.6rem #00000029;
    border-radius: 0.5rem;
    width: 100%;
    height: 3.5rem;
    font: 500 1.1rem/1.4rem Mont;
    color: #ffffff;
  }
  &--selected {
    background: $apollo-secondary 0% 0% no-repeat padding-box;
  }
  &__button-container {
    @include xs {
      padding-top: 2rem;
    }
  }
}

@mixin partner-style($parter-code, $selectable-color, $selectable-background, $selected-color, $selected-background, $price-color) {
  .partner_#{$parter-code} {
    .cover {
      &__select {
        color: $selectable-color;
        background: $selectable-background 0% 0% no-repeat padding-box;
      }

      &--selected {
        color: $selected-color;
        background: $selected-background 0% 0% no-repeat padding-box;
      }

      &__dailyprice {
        color: $price-color;
      }    
    }
  }
}

@mixin vendor-style($vendor-code, $color, $price-highlight) {
  .vendor_#{$vendor-code} {
    .cover-extras-tabs {
      &__header {
        border: 0.1rem solid $color;
        border-bottom: none;
        color: $color;

        &--selected {
          @include md {
            background: $color 0% 0% no-repeat padding-box;
            color: #ffffff;
          }
        }    
      }

      &__content {
        border: 0.1rem solid $color;

        .booking-details-section {
          border-bottom: 0.1rem solid $color;

          &__toggle {
            color: $color;
          }
        }
      }  
      
      &__info {
        .MuiTooltip-tooltip {
          background: $color;
        }
    
        .MuiTooltip-arrow::before{
          color: $color;
        }
    
        .MuiButton-root {
          .MuiButton-label {
            svg {
              color: $color;
            }
          }
        }
      }
    }

    .cover {
      &__name {
        color: $color;
      }
      
      &__due-text {
        color: $apollo-primary;
      }
    
      &__inclusions {
        color: $color;

        &--list-check {
          .MuiSvgIcon-root {
            color: $color;
          }
        }
      }
    }
  }
}

@include vendor-style(CCAU, $cheapa-primary, $apollo-secondary);
@include vendor-style(CCNZ, $cheapa-primary, $apollo-secondary);
@include vendor-style(CCUS, $cheapa-primary, $apollo-secondary);

@include vendor-style(HCAU, $hippie-primary, $apollo-secondary);
@include vendor-style(HCNZ, $hippie-primary, $apollo-secondary);

@include vendor-style(APAU, $apollo-secondary, $apollo-secondary);
@include vendor-style(APDE, $apollo-secondary, $apollo-secondary);
@include vendor-style(APFR, $apollo-secondary, $apollo-secondary);
@include vendor-style(APNZ, $apollo-secondary, $apollo-secondary);
@include vendor-style(APUK, $apollo-secondary, $apollo-secondary);
@include vendor-style(APIE, $apollo-secondary, $apollo-secondary);
@include vendor-style(APUS, $apollo-secondary, $apollo-secondary);

@include vendor-style(FFAU, $apollo-secondary, $apollo-secondary);
@include vendor-style(ZEAU, $apollo-secondary, $apollo-secondary);
@include vendor-style(FFNZ, $apollo-secondary, $apollo-secondary);
@include vendor-style(ZENZ, $apollo-secondary, $apollo-secondary);
@include vendor-style(FFUS, $apollo-secondary, $apollo-secondary);
@include vendor-style(ZEUS, $apollo-secondary, $apollo-secondary);

@include vendor-style(SRAU, $star-primary, $apollo-secondary);
@include vendor-style(SRNZ, $star-primary, $apollo-secondary);
@include vendor-style(SRUS, $star-primary, $apollo-secondary);

@include vendor-style(BKGB, $cheapa-primary, $apollo-secondary);
@include vendor-style(BKIE, $cheapa-primary, $apollo-secondary);


@include partner-style(WWAPMHCO, #ffffff, transparent linear-gradient(280deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%), #ffffff, $apollo-secondary, $apollo-secondary);

@include partner-style(WWCCMHCO, #ffffff, $cheapa-primary, #ffffff, $cheapa-secondary, $cheapa-secondary);
@include partner-style(WWCCMHNZ, #ffffff, $cheapa-primary, #ffffff, $cheapa-secondary, $cheapa-secondary);
@include partner-style(WWCCMHCA, #ffffff, $cheapa-primary, #ffffff, $cheapa-secondary, $cheapa-secondary);

@include partner-style(WWHIPCOM, #ffffff, $hippie-secondary, #ffffff, $hippie-primary, $hippie-primary);
@include partner-style(WWNZHIP, #ffffff, $hippie-secondary, #ffffff, $hippie-primary, $hippie-primary);

@include partner-style(STARRV, #ffffff, $star-primary, #ffffff, $star-secondary, $star-secondary);

@include partner-style(WWBUNK, #ffffff, $bunk-primary, #ffffff, $bunk-secondary, $bunk-secondary);

@include partner-style(RACQAFF, $racx-secondary, $racx-primary, #ffffff, $racx-secondary, $racx-secondary);
@include partner-style(RACV, $racx-secondary, $racx-primary, #ffffff, $racx-secondary, $racx-secondary);
