$header-container-color: #ffffff;
$header-container-background: #58595b;

$apollo-primary: #f5822b;
$apollo-primary-gradient-end: #ec5000;
$apollo-secondary: #006940;

// $star-primary: #808285;
$star-primary: #58595b;
$star-secondary: #000000;

$cheapa-primary: #00acec;
$cheapa-secondary: #006c93;

// $hippie-primary: #3c1915;
$hippie-primary: #3d1a16;
$hippie-secondary: #bf301a;

$bunk-primary: #00acec;
$bunk-secondary: #006c93;

$racx-primary: #ffe600;
$racx-secondary: #003478;