@import '../../styles/mixins';
@import '../../styles/brandColours';

.page-content{
  width:95%;
  max-width: 1200px;
  margin:2rem auto;
  @include sm{
    margin:auto;
    width:100%;
  }
}
.cover-and-extras {
  &__header {
    background: transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    padding-left: 2rem;
    @include xs {
      padding-left: 0;
    }
  }
  &__rental-details {
    font: 700 1.5rem/4.4rem Mont;
    color: #ffffff;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    margin: 5px 0 0 0;
    padding: 3px 5px 0 12px;
    white-space: nowrap;
    @include xs {
      justify-content: center;
      font-size: 1.3rem;
      margin: 5px auto 0;
    }
    @include xxs {
      justify-content: center;
      font-size: 1.1rem;
      margin: 5px auto 0;
    }
    .MuiSvgIcon-root {
      margin-left: 5px;
      font-size: 2rem;
      vertical-align: middle;
    }
  }

  &__important-info {
    @extend .cover-and-extras__rental-details;
    white-space: nowrap;
  }
  &__content {
    padding: 2rem;
  }

  &--bold {
    > div {
      border-radius: 5px 5px 0 0;
      background: white;
      color: $apollo-primary;
    }
  }
  &--hidden {
    display: none;
  }
}

@mixin partner-theme($partner-code, $text-color, $inverted-text-color, $background) {
  .partner_#{$partner-code} {
    .cover-and-extras {
      &__header {
        background: $background 0% 0% no-repeat padding-box;
      }

      &__rental-details {
        color: $inverted-text-color;
      }

      &--bold {
        > div {
          border-radius: 5px 5px 0 0;
          background: $inverted-text-color;
          color: $text-color;
        }
      }
    }
  }
}

@include partner-theme(WWAPMHCO, $apollo-primary, #ffffff, transparent linear-gradient(273deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%));

@include partner-theme(WWCCMHCO, $cheapa-primary, #ffffff, $cheapa-primary);
@include partner-theme(WWCCMHNZ, $cheapa-primary, #ffffff, $cheapa-primary);
@include partner-theme(WWCCMHCA, $cheapa-primary, #ffffff, $cheapa-primary);

@include partner-theme(WWHIPCOM, $hippie-primary, #ffffff, $hippie-primary);
@include partner-theme(WWNZHIP, $hippie-primary, #ffffff, $hippie-primary);

@include partner-theme(STARRV, $star-primary, #ffffff, $star-primary);

@include partner-theme(WWBUNK, $bunk-primary, #ffffff, $bunk-primary);

@include partner-theme(RACQAFF, #ffffff, $racx-secondary, $racx-primary);
@include partner-theme(RACV, #ffffff, $racx-secondary, $racx-primary);
