@import '../../styles/brandColours';

.action-buttons {
  &__primary-fullwidth {
    height: 52px;
    line-height: 52px;
    width: 100%;
    background: transparent linear-gradient(99deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 0px 0.3rem 0.6rem #00000029;
    border-radius: 0.5rem;
    text-align: center;
    font: 600 1.6rem Mont;
    color: #ffffff;

    &:disabled {
      background: grey;
    }
  }

  &__join {
    margin-top: 1rem;
    height: 52px;
    line-height: 52px;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid $apollo-primary;
    border-radius: 0.5rem;
    text-align: center;
    font: 700 1.4rem Mont;
    color: $apollo-primary;
  }

  &__email {
    font: 350 1.2rem Mont;
    color: $header-container-background;
    background: none;
    border: none;
  }
}

@mixin partner-style($partner-code, $proceed-color, $proceed-background, $email-quote-color) {
  .partner_#{$partner-code} {
    .action-buttons {
      &__primary-fullwidth {
        background: $proceed-background 0% 0% no-repeat padding-box;
        color: $proceed-color;
      }

      &__join {
        border: 1px solid $email-quote-color;
        color: $email-quote-color;
      }  
    }
  }
}

@include partner-style(WWAPMHCO, #ffffff, transparent linear-gradient(99deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%), $apollo-primary);

@include partner-style(WWCCMHCO, #ffffff, $cheapa-primary, $cheapa-primary);
@include partner-style(WWCCMHNZ, #ffffff, $cheapa-primary, $cheapa-primary);
@include partner-style(WWCCMHCA, #ffffff, $cheapa-primary, $cheapa-primary);

@include partner-style(WWHIPCOM, #ffffff, $hippie-secondary, $hippie-secondary);
@include partner-style(WWNZHIP, #ffffff, $hippie-secondary, $hippie-secondary);

@include partner-style(STARRV, #ffffff, $star-primary, $star-primary);

@include partner-style(WWBUNK, #ffffff, $bunk-primary, $bunk-primary);

@include partner-style(RACQAFF, $racx-secondary, $racx-primary, $racx-secondary);
@include partner-style(RACV, $racx-secondary, $racx-primary, $racx-secondary);