*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  // For default 16px browser size this will be 10px
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  margin: 0 auto;
}
