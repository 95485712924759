@import '../../styles/brandColours';

.providerLogo {
  height: 60px;
}

.disclaimerText {
  font-size: x-small;
  margin-top: 10px;
}

.noBullets {
  list-style: none;
  margin-left: 0px;
}

.validationResultText {
  margin-top: 10px;
  > svg {
    font-size: x-large;
    vertical-align: middle;
  }
}

.validationResultFailText {
  color: #ec4949;
}

.validationResultSuccessText {
  color: #006940;
  margin-bottom: 10px;
}

.QFFPrimaryText {
  color: #ec4949;
}

.loyaltyFormContainer {
  input[type='text'], select {
    width: 100%;
    height: 4.33rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0.3rem 0.6rem #0000001c;
    border: 1px solid $apollo-primary;
    border-radius: 0.5rem;
    opacity: 1;
    margin-top: 0.5rem;
    padding-left: 1rem;
  }
}