@import '../../styles/brandColours';

.tc {
  display: flex;
  align-items: center;
  margin-top: 1.2em;

  input {
    margin-left: 0px !important;
  }
  > label {
    display: inline-block;
    margin-top: 0px;
  }
  a {
    color: $apollo-primary !important;
    &:hover {
      color: $apollo-primary-gradient-end !important;
    }
    &:visited {
      color: $apollo-primary-gradient-end !important;
    }
  }
}
