@import '../../styles/mixins';

.surcharge-rate {
  width: 100%;

  .card-charges {
    background-color: white;
    margin-top: 1em;

    &__line {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
    }
    &__icon {
      width: 30px;
      text-align: center;
      font-size: 1.2rem;
      svg {
        width: 100%;
        vertical-align: middle;
      }
      @include xxs {
        font-size: 1rem;
      }
    }
    &-selected {
      border: 1px solid #f5812a;
    }

    &-muted {
      opacity: 0.4;
      filter: alpha(opacity=40);
    }
  }
}
