@import '../../../styles/mixins';
@import '../../../styles/brandColours';

.CampStayInfo {
  font-size: 1.3rem;
}

.CampStayCard {
  &__content {
    border: 1px solid $apollo-primary;
    border-radius: 5px;
    overflow: hidden;
    height: 250px;
    position: relative;
    margin-top: 1em;
    margin-bottom: 1em;

    @include sm {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    
    &--text {
      padding: 5px;

      a {
        color: $apollo-primary;
        &:hover, &:visited {
          color: $apollo-primary;
        }
      }
    }

    &--img {
      img {
        width: 100%;
        height: 120px;
        @include sm {
          height: 150px;
        }
      }
    }
  }
  a {
    color: $apollo-primary;
    // margin-top: 1em;
    &:hover, &:visited {
      color: $apollo-primary;
    }
  }
}
