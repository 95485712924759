@import '../../styles/mixins';
@import '../../styles/brandColours';

.dialog {
  overflow-y: visible !important;
}

.title {
  font-size: 1.875rem;
  font-weight: 700;
}

.gridContainer {
  :global(.MuiGrid-item) {
    padding: 0.5rem;
  }
}

.select,
input.datePicker {
  width: 100%;
  height: 4.33rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.3rem 0.6rem #0000001c;
  border: 1px solid #f5822b !important;
  border-radius: 0.5rem;
  opacity: 1;
  margin-top: 0.5rem;
  padding-left: 1rem;
  font-size: 0.8em;
}

.datePickerWrapper {
  :global(.react-datepicker-wrapper) {
    display: block;
  }
}

.primaryActionButton {
  color: #ffffff;
  background: transparent
    linear-gradient(280deg, $apollo-primary-gradient-end 0%, $apollo-primary 100%) 0% 0% no-repeat
    padding-box;
  border: none;
  border-radius: 0.5rem;
  text-align: center;
  font: 600 1.6rem Mont;
  width: 100%;
  height: 5rem;
  position: relative;
  margin-top: 20px;
}

.secondaryActionButton {
  width: 100%;
  height: 4rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.1rem solid $apollo-secondary;
  border-radius: 0.5rem;
  font: Bold 1.4rem/2rem Mont;
  color: $apollo-secondary;
}

.chargeAmount {
  display: inline;
  margin-left: 1rem;
}

.alert {
  background-color: rgb(24, 6, 5);
  color: rgb(250, 179, 174);
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1.2rem;

  :global(.MuiSvgIcon-root) {
    margin: 0 1rem;
    vertical-align: sub;
  }
}

.inProgressNotice {
  margin: 20px;
  text-align: center;
}

.searchResultDisplayNotice {
  font-size: 0.8em;

  .currency {
    font-size: 0.8rem;
    margin-right: 5px;
  }

  .originalRentalPriceLine {
    text-align: right;
    margin-top: 10px;
  }

  .rentalPriceDifferenceLine {
    text-align: right;
  }

  .newRentalPriceLine {
    text-align: right;

    .chargeAmount {
      font-weight: bold;
    }
  }
}