@import '../../styles/mixins';
@import '../../styles/brandColours';

.booking-confirmed__title {
  svg {
    margin-left: 0.3em;
    margin-bottom: -1px;
  
    .a {
      fill: $apollo-primary;
    }
  
    .b {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.page-content{
  width: 95%;
  margin: 2rem auto;

  @include sm{
    margin: auto;
    width: 100%;
  }
}

.payment-confirmation {
  &__title {
    text-align: center;
    font: 700 4rem/5.9rem Mont;
    color: $apollo-primary;
  }
  &__spinner {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border: solid 4px transparent;
    border-radius: 50%;
    animation: payment-confirmation__spinner 600ms linear infinite;
  }
}

.booking-confirmed {
  padding: 1rem;

  &__title {
    font: Bold 3.5rem Mont;
    color: $apollo-primary;
  }

  &__secondary {
    font: 2rem Mont;
    color: $apollo-secondary;
    opacity: 1;
  }
  &__tertiary {
    font: 1.8rem;
    font-weight: 600;
    text-decoration: underline;
  }
  &__reservation {
    font: Bold 5.4rem Mont;
    color: $apollo-secondary;
    opacity: 1;
  }

  &__header {
    font-weight: bold;
  }

  &__text {
    font-size: 1.2rem;
  }

  &__additional {
    font-style: italic;
    font-size: 1.2rem;
  }

  &__remaining {
    &--content {
      padding: 0 1rem 1rem 1rem;
      border: 1px solid $apollo-primary;
      border-radius: 5px;
      > div {
        padding-top: 0.5rem;
      }
    }
    @include sm {
      padding: 2rem 0;
    }
  }

  &__currency {
    vertical-align: middle;
    font: 700 1.2rem Mont;
    color: $apollo-secondary;
    text-align: right;
  }
}


@mixin partner-theme($partner-code, $primary-color, $secondary-color) {
  .partner_#{$partner-code} {
    .booking-confirmed__title {
      svg {
        .a {
          fill: $primary-color;
        }
      }
    }

    .payment-confirmation {
      &__title {
        color: $primary-color;
      }
    }

    .booking-confirmed {
      &__title {
        color: $primary-color;
      }
    
      &__secondary {
        color: $secondary-color;
      }

      &__reservation {
        color: $secondary-color;
      }
    
      &__currency {
        color: $secondary-color;
      }
    }
  }
}

@include partner-theme(WWAPMHCO, $apollo-primary, $apollo-secondary);

@include partner-theme(WWCCMHCO, $cheapa-primary, $cheapa-secondary);
