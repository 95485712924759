@import '../../styles/mixins';

.page-content{
  width:95%;
  max-width: 1200px;
  margin:2rem auto;
  @include sm{
    margin:auto;
    width:100%;
  }
}