$colors: (
  primary: (
    light: #f5812a,
    dark: #ec5210,
    dark-grey: #58595b,
  ),
  apollo: (
    logogreen: #006940,
    logo-orange-dark: #ec5210,
    logo-orange-light: #f5812a,
  ),
  links: (
    selected: #000,
  ),
);

$default-text-color: #58595b;

@function color($color-name, $color-variant) {
  @return map-get(map-get($colors, $color-name), $color-variant);
}
