@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/brandColours';

.price_text {
  margin: auto;
  text-align: right;
  justify-content: flex-end;
  @include sm {
    margin: 0;
    text-align: center;
  }
}

.currency {
  padding-right: 1rem;
  display: inline;

  @include md {
    font: 700 2.7rem/3.5rem Mont;
  }
  @include xs {
    font: 700 2.2rem/3.1rem Mont;
  }
  @include xxs {
    font: 700 1.4rem/0rem Mont;
  }
}

.amount {
  display: inline;

  @include md {
    font: 700 2.7rem/3.5rem Mont;
  }
  @include xs {
    font: 700 2.2rem/3.1rem Mont;
  }
  @include xxs {
    font: 700 1.4rem/0rem Mont;
  }
}

.rental_only_charge {
  font: 400 1.4rem/1.8rem Mont;
  // padding-bottom: 1rem;
}

@mixin themable($vendor-code, $color, $second-color) {
  .#{$vendor-code} {
    .amount {
      color: $color;
    }    
  }
}

@include themable(CCAU, $cheapa-primary, $cheapa-primary);
@include themable(CCNZ, $cheapa-primary, $cheapa-primary);
@include themable(CCUS, $cheapa-primary, $cheapa-primary);

@include themable(HCAU, $hippie-primary, $hippie-primary);
@include themable(HCNZ, $hippie-primary, $hippie-primary);

@include themable(APAU, $apollo-primary, $apollo-secondary);
@include themable(APDE, $apollo-primary, $apollo-secondary);
@include themable(APFR, $apollo-primary, $apollo-secondary);
@include themable(APNZ, $apollo-primary, $apollo-secondary);
@include themable(APUK, $apollo-primary, $apollo-secondary);
@include themable(APIE, $apollo-primary, $apollo-secondary);
@include themable(APUS, $apollo-primary, $apollo-secondary);

@include themable(FFAU, $apollo-primary, $apollo-secondary);
@include themable(ZEAU, $apollo-primary, $apollo-secondary);
@include themable(FFNZ, $apollo-primary, $apollo-secondary);
@include themable(ZENZ, $apollo-primary, $apollo-secondary);
@include themable(FFUS, $apollo-primary, $apollo-secondary);
@include themable(ZEUS, $apollo-primary, $apollo-secondary);

@include themable(SRAU, $star-primary, $star-primary);
@include themable(SRNZ, $star-primary, $star-primary);
@include themable(SRUS, $star-primary, $star-primary);

@include themable(BKGB, $cheapa-primary, $cheapa-primary);
@include themable(BKIE, $cheapa-primary, $cheapa-primary);