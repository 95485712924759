@import '../../styles/brandColours';

.container {
  &__header {
    font: 700 1.7rem Mont;
    background: $header-container-background 0% 0% no-repeat padding-box;
    color: $header-container-color;
    padding: 1rem;
    border-radius: 0.5rem 0.5rem 0px 0px;
  }
  &__icon {
    > svg {
      width: 2rem;
    }
    text-align: right;
    vertical-align: top;
  }
}
