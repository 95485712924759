@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/brandColours';

.dialog_container {
  padding: 10px;
}

.special_search_result_item {
  border-radius: 0.5rem;
  padding: 1.5rem;
  background-color: #ffffff;
}

.deal_result_deltas {
  margin-top: 2em !important;
  margin-bottom: 1em !important;
}

.heavy {
  text-align: left;
  font: 700 1.6rem/2.4rem Mont;
  letter-spacing: 0;
}

.light {
  text-align: left;
  font: 400 1.3rem Mont;
  letter-spacing: 0;
}

.sub_header {
  text-align: left;
  font: 700 1.6rem/2.4rem Mont;
  letter-spacing: 0;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding-top: 1em;
}

.vehicle_blurb {
  text-align: justify;
  font: 400 1.3rem/2.1rem Mont;
  letter-spacing: 0;
}

.primary_button {
  text-align: center;
  font: 700 1.6rem/2.4rem Mont;
  color: #ffffff;
  height: 5.2rem;
  width: 100%;
  padding: 0px 10px;
  box-shadow: 0rem 0.3rem 0.6rem #000029;
  border-radius: 0.5rem;
}

.secondary_button {
  text-align: center;
  font: 700 1.6rem/2.4rem Mont;
  background-color: #ffffff;
  color: $apollo-secondary;
  border: 1px solid $apollo-secondary;
  height: 5.2rem;
  width: 100%;
  padding: 0px 10px;
  border-radius: 0.5rem;
}

.image {
  width: 85%;
  @include sm {
    width: 100%;
  }
}

.image_wrapper {
  text-align: right;
  @include sm {
    text-align: center;
  }
}

.highlight {
  font-weight: 900;
  font-size: x-large;
}

.view_more_link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.2rem;
  font: 700 1.6rem/2.1rem Mont;
  color: white !important;
  border-radius: 0.5rem;
  background-color: $apollo-primary;
  text-decoration: none !important;
  width: 100%;
}

@mixin themable($vendor-code, $color, $second-color) {
  .#{$vendor-code} {
    .special_search_result_item {
      border: 0.2rem solid $color;
    }
    
    .primary_button {
      background: $second-color 0% 0% no-repeat padding-box;
      border: 0.1rem solid $second-color;
    }

    .secondary_button {
      color: $second-color;
      border: 1px solid $second-color;
    }    

    .highlight {
      color: $color;
    }
  }
}

@include themable(CCAU, $cheapa-primary, $cheapa-primary);
@include themable(CCNZ, $cheapa-primary, $cheapa-primary);
@include themable(CCUS, $cheapa-primary, $cheapa-primary);

@include themable(HCAU, $hippie-primary, $hippie-primary);
@include themable(HCNZ, $hippie-primary, $hippie-primary);

@include themable(APAU, $apollo-primary, $apollo-secondary);
@include themable(APDE, $apollo-primary, $apollo-secondary);
@include themable(APFR, $apollo-primary, $apollo-secondary);
@include themable(APNZ, $apollo-primary, $apollo-secondary);
@include themable(APUK, $apollo-primary, $apollo-secondary);
@include themable(APIE, $apollo-primary, $apollo-secondary);
@include themable(APUS, $apollo-primary, $apollo-secondary);

@include themable(FFAU, $apollo-primary, $apollo-secondary);
@include themable(ZEAU, $apollo-primary, $apollo-secondary);
@include themable(FFNZ, $apollo-primary, $apollo-secondary);
@include themable(ZENZ, $apollo-primary, $apollo-secondary);
@include themable(FFUS, $apollo-primary, $apollo-secondary);
@include themable(ZEUS, $apollo-primary, $apollo-secondary);

@include themable(SRAU, $star-primary, $star-primary);
@include themable(SRNZ, $star-primary, $star-primary);
@include themable(SRUS, $star-primary, $star-primary);

@include themable(BKGB, $cheapa-primary, $cheapa-primary);
@include themable(BKIE, $cheapa-primary, $cheapa-primary);
