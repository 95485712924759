@import '../../styles/mixins';
@import '../../styles/brandColours';

.booking-summary {
  > * {
    padding: 1rem;
  }

  > *:last-child {
    padding-bottom: 0;
  }

  border: 1px solid #d3d3d3;
  border-radius: 0.5rem;
  &__header {
    font: 700 1.7rem Mont;
    background: $header-container-background 0% 0% no-repeat padding-box;
    color: white;
    text-align: center;
    padding: 1rem;
    border-radius: 0.5rem 0.5rem 0px 0px;
  }
  &__header-arrow {
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      margin-left:5px;
    }
  }
  &__total {
    &-payable {
      > :first-child {
        font: 400 1.2rem Mont;
      }
      padding: 1rem;
      border-radius: 0.5rem;
      background: $apollo-secondary;
      text-align: right;
      color: white;
      margin-top: 0.5em;
    }
    &-amount {
      font: 700 2.1rem Mont;
    }
    &-currency {
      font: 700 2.1rem Mont;
    }
    &-title {
      font: 600 1.4rem Mont;
      display: inline-block;
      width: 70%;
      text-align: left;
    }
    &-subtitle {
      font: 600 1.4rem Mont;
      display: inline-block;
      width: 70%;
      text-align: left;
      font-size: 0.7em;
      padding-left: 0.5em;
    }
  }
  &--content-hidden {
    border-radius: 0.5rem;
  }
}

@mixin partner-style($partner-code, $header-color, $header-background, $payment-total-color, $payment-total-background) {
  .partner_#{$partner-code} {
    .booking-summary {
      &__header {
        color: $header-color;
        background: $header-background 0% 0% no-repeat padding-box;
      }
  
      &__total {
        &-payable {
          color: $payment-total-color;
          background: $payment-total-background;
        }
      }
    }
  }
}

@include partner-style(WWAPMHCO, $header-container-color, $header-container-background, #ffffff, $apollo-secondary);

@include partner-style(WWCCMHCO, $header-container-color, $header-container-background, #ffffff, $cheapa-secondary);
@include partner-style(WWCCMHNZ, $header-container-color, $header-container-background, #ffffff, $cheapa-secondary);
@include partner-style(WWCCMHCA, $header-container-color, $header-container-background, #ffffff, $cheapa-secondary);

@include partner-style(WWHIPCOM, $header-container-color, $header-container-background, #ffffff, $hippie-primary);
@include partner-style(WWNZHIP, $header-container-color, $header-container-background, #ffffff, $hippie-primary);

@include partner-style(STARRV, $header-container-color, $header-container-background, #ffffff, $star-secondary);

@include partner-style(WWBUNK, $header-container-color, $header-container-background, #ffffff, $bunk-secondary);

@include partner-style(RACQAFF, $header-container-color, $header-container-background, #ffffff, $racx-secondary);
@include partner-style(RACV, $header-container-color, $header-container-background, #ffffff, $racx-secondary);
