@import '../../styles/brandColours';

.es {
  display: flex;
  align-items: center;

  input {
    margin-left: 0px !important;
  }

  > label {
    display: inline-block;
    margin-top: 2px;
    padding: 0px;
  }
}