@import '../../styles/brandColours';

.booking-summary-section {
  text-align: justify;
  border-bottom: 1px solid lightgrey;

  &__header {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    &--title {
      font: 600 1.4rem Mont;
    }
    &--sub {
      font: 400 1rem Mont;
    }
  }
  &__value {
    width: 30%;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    font: 700 1.2rem Mont;
    color: $apollo-secondary;
  }
}

.currency {
  font: 700 0.8rem Mont;
}
