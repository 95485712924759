@import '../../styles/mixins';
@import '../../styles/brandColours';

.go-back-button {
  &__go-back {
    width: 8.4rem;
    height: 4.2rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.1rem solid $apollo-secondary;
    border-radius: 0.5rem;
    font: Bold 1.4rem/2rem Mont;
    color: $apollo-secondary;
    @include xs{
      margin-top:1rem;
    }
  }
}
