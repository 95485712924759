@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/brandColours';

@mixin themable($vendor-code, $color, $second-color) {
  .loyaltyProgramLogoList {
    font: 400 1.3rem/1.2rem Mont;
    text-align: left;

    &:last-child {
      margin-bottom: -10px;
    }
  }

  .loyaltyProgramLogo {
    max-height: 40px;
    margin: 10px 0px 0px 0px;
  }

  .loyaltyProgramEarnText {
    padding-left: 60px;
    padding-top: 0.5em;
  }

  .loyaltyProgramDisclaimerText {
    font-size: x-small;
    margin-top: 10px;  
  }

  .challenged .search-result-item__price-text {
    opacity: 0.05;
  }

  .search-result-item--#{$vendor-code} {
    margin-bottom: 1.5rem;
    width: 100%;
    .not-available {
      font-size: 13px;
      margin-right: 0px;
      text-align: center;
    }
    .search-result-item {
      border: 0.2rem solid $color;
      border-radius: 0.5rem;
      margin-top: 0.2rem;
      padding: 0 1.5rem 1.5rem 1.5rem;
      background-color: #ffffff;
      &__rates {
        border: 0.2rem solid $color;
        margin-top: -0.5rem;
        border-radius: 0 0 0.5rem 0.5rem;
        .daily-rates {
          &__header {
            background-color: $color;
            color: white;
            padding: 0.5rem;
            @include xxs {
              font-size: 1.2rem;
            }
          }

          &__item {
            border-bottom: 0.5px solid $color;
            padding: 1rem;
            @include xxs {
              font-size: 1.2rem;
            }
          }
        }
      }
      &--col-padding {
        padding-top: 0.1rem;
        padding-bottom: 0.5rem;
        .feature-table {
          margin-bottom: 10px;
        }
      }

      &__logo {
        height: 3.8rem;
        margin-right: 1rem;
      }

      &__description {
        text-align: left;
        font: 400 1.3rem/2.1rem Mont;
        letter-spacing: 0;
      }
      &__more-details {
        display: inline-block;
        margin-left: 0.5rem;
      }

      &__include-features {
        text-align: left;
        font: 700 1.6rem/2.4rem Mont;
        letter-spacing: 0;
      }

      &__image-wrapper {
        text-align: right;
        @include sm {
          text-align: center;
        }
      }

      &__image {
        width: 85%;
        @include sm {
          width: 100%;
        }
      }

      &__spec {
        font: 600 1.6rem/2.4rem Mont;
        color: $second-color;
        text-align: end;
        margin-right: 1.5rem;
        fill: $second-color;
      }

      &__select-vehicle {
        text-align: end;
        &--button {
          padding-left: 5px;
        }
        &__see-daily-rates {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 5.2rem;
          width: 100%;
          font: 700 1.6rem/2.1rem Mont;
          color: $second-color;
          border: 0.1rem solid $second-color;
          border-radius: 0.5rem;
          background-color: white;
          fill: $second-color;
          @include sm {
            margin-left: 0px;
          }
          > svg {
            vertical-align: middle;
            margin-left: 5px;
          }
        }

        &__select-vehicle-button {
          background: $second-color 0% 0% no-repeat padding-box;
          text-align: center;
          font: 700 1.6rem/2.4rem Mont;
          color: #ffffff;
          height: 5.2rem;
          width: 100%;
          padding: 0px 10px;
          box-shadow: 0rem 0.3rem 0.6rem #00000029;
          border-radius: 0.5rem;
          border: 0.1rem solid $second-color;
          @include sm {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }
}

@include themable(CCAU, $cheapa-primary, $cheapa-primary);
@include themable(CCNZ, $cheapa-primary, $cheapa-primary);
@include themable(CCUS, $cheapa-primary, $cheapa-primary);

@include themable(HCAU, $hippie-primary, $hippie-primary);
@include themable(HCNZ, $hippie-primary, $hippie-primary);

@include themable(APAU, $apollo-primary, $apollo-secondary);
@include themable(APDE, $apollo-primary, $apollo-secondary);
@include themable(APFR, $apollo-primary, $apollo-secondary);
@include themable(APNZ, $apollo-primary, $apollo-secondary);
@include themable(APUK, $apollo-primary, $apollo-secondary);
@include themable(APIE, $apollo-primary, $apollo-secondary);
@include themable(APUS, $apollo-primary, $apollo-secondary);

@include themable(FFAU, $apollo-primary, $apollo-secondary);
@include themable(ZEAU, $apollo-primary, $apollo-secondary);
@include themable(FFNZ, $apollo-primary, $apollo-secondary);
@include themable(ZENZ, $apollo-primary, $apollo-secondary);
@include themable(FFUS, $apollo-primary, $apollo-secondary);
@include themable(ZEUS, $apollo-primary, $apollo-secondary);

@include themable(SRAU, $star-primary, $star-primary);
@include themable(SRNZ, $star-primary, $star-primary);
@include themable(SRUS, $star-primary, $star-primary);

@include themable(BKGB, $cheapa-primary, $cheapa-primary);
@include themable(BKIE, $cheapa-primary, $cheapa-primary);
