.u-margin-top-small {
  margin-top: 0.5rem;
}

.u-margin-top-med {
  margin-top: 1.5rem;
}

.u-margin-left-med {
  margin-left: 2rem;
}

.u-error {
  color: red;
}

.u-italic {
  font-style: italic;
}
