@import '../../styles/mixins';
@import '../../styles/brandColours';

@mixin feature-color($vendor-code, $color) {
  &--icon-color--#{$vendor-code} {
    fill: $color;
  }
}

.name-berth {
  &__customer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__berth {
    font: 700 22px/32px Mont;
  }
  &__name {
    @include md {
      font: 700 2.3rem/3.5rem Mont;
    }
    @include xs {
      font: 700 1.7rem/2.4rem Mont;
    }
    margin-right: 1rem;
    @include sm {
      margin: 1rem 0;
    }
  }

  &__human {
    margin-right: 1rem;
  }

  @include feature-color(CCAU, $cheapa-primary);
  @include feature-color(CCNZ, $cheapa-primary);
  @include feature-color(CCUS, $cheapa-primary);

  @include feature-color(HCAU, $hippie-primary);
  @include feature-color(HCNZ, $hippie-primary);

  @include feature-color(APAU, $apollo-primary);
  @include feature-color(APDE, $apollo-primary);
  @include feature-color(APFR, $apollo-primary);
  @include feature-color(APNZ, $apollo-primary);
  @include feature-color(APUK, $apollo-primary);
  @include feature-color(APIE, $apollo-primary);
  @include feature-color(APUS, $apollo-primary);

  @include feature-color(FFAU, $apollo-primary);
  @include feature-color(ZEAU, $apollo-primary);
  @include feature-color(FFNZ, $apollo-primary);
  @include feature-color(ZENZ, $apollo-primary);
  @include feature-color(FFUS, $apollo-primary);
  @include feature-color(ZEUS, $apollo-primary);

  @include feature-color(SRAU, $star-primary);
  @include feature-color(SRNZ, $star-primary);
  @include feature-color(SRUS, $star-primary);

  @include feature-color(BKGB, $cheapa-primary);
  @include feature-color(BKIE, $cheapa-primary);
}
