@import '../../styles/variables';
@import '../../styles/mixins';

.booking-details {
  &__body {
    margin: 0;
    @include sm {
      margin: 2rem;
    }
  }
  &__body-content {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  &__sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 230px;
    bottom: 20px;
    align-self: flex-start;
    @include sm {
      position: relative;
      width: 100%;
      top: 0px;
      bottom: 0px;
      max-height:none;
      overflow:unset;
    }
  }

  &__summary {
    margin: 0 2rem;
    text-align: center;
  }

  &__booking-summary {
    width: 300px;
    display: inline-block;
    background: white;
    @include sm {
      width: 100%;
    }
  }
  &__nav {
    padding-bottom: 2rem;
  }
  &__info-pane {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.1rem solid #d3d3d3;
    border-radius: 0.5rem;
  }
  &__pd-container {
    @include xs {
      padding: 2rem;
    }
  }
}
