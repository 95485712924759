@import './brandColours';

.partner-border {
  border: 1px solid $apollo-primary;
}

.partner-link {
  color: $apollo-secondary !important;

  cursor: pointer;
  text-decoration: underline !important;

  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.7;
  font-family: Mont;

  border: none;
  background-color: transparent;
}

@mixin partner-helpers($partner-code, $primary-color) {
  .partner_#{$partner-code} {
    .partner-border {
      border: 1px solid $primary-color;
    }
  
    .partner-link {
      color: $primary-color !important;
    }
  }
}

@include partner-helpers(WWCCMHCO, $cheapa-primary);
@include partner-helpers(WWCCMHNZ, $cheapa-primary);
@include partner-helpers(WWCCMHCA, $cheapa-primary);

@include partner-helpers(WWHIPCOM, $hippie-secondary);
@include partner-helpers(WWNZHIP, $hippie-secondary);

@include partner-helpers(STARRV, $star-primary);

@include partner-helpers(WWBUNK, $bunk-primary);

@include partner-helpers(RACQAFF, $racx-secondary);
@include partner-helpers(RACV, $racx-secondary);