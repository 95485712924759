@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/brandColours';

.booking-details-extras-item {
  text-align: center;
  display: block;
  padding: 2rem;
  &__wrapper {
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include sm {
      height: 160px;
    }
  }
  &__icon {
    display: inline-block;
    text-align: center;
    height: 4rem;
    width: auto;
    fill: $apollo-secondary;
  }
  &__name {
    font: 400 1rem/1.3rem Mont;
  }
  &__price {
    margin-top: 1rem;
    font: 700 1rem/1.3rem Mont;
  }
  &__count {
    font: 700 1.4rem/4.3rem Mont;
    color: $apollo-secondary;
    border: 0.1rem solid #d3d3d3;
    border-radius: 1rem;
    &--plus-enabled {
      background: #f0f0f0 0% 0% no-repeat padding-box;
      border-radius: 0 1rem 1rem 0;
    }
    &--minus-enabled {
      background: #f0f0f0 0% 0% no-repeat padding-box;
      border-radius: 1rem 0rem 0rem 1rem;
    }

    &__number {
      border-left: 0.1rem solid #d3d3d3;
      border-right: 0.1rem solid #d3d3d3;
    }
  }
}
