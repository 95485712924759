@import '../../styles/brandColours';

@mixin feature-color($vendor-code, $color) {
  &--icon-color--#{$vendor-code} {
    fill: $color;
  }
}

.features {
  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap:wrap;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom:0.5rem;
  }

  &__icon {
    vertical-align: middle;
    padding-right: 0.5rem;
    display: inline;
    > svg {
      height: 2.5rem;
      padding-top: 0.1rem;
      width: auto;
      vertical-align: middle;
    }
  }

  @include feature-color(CCAU, $cheapa-primary);
  @include feature-color(CCNZ, $cheapa-primary);
  @include feature-color(CCUS, $cheapa-primary);

  @include feature-color(HCAU, $hippie-primary);
  @include feature-color(HCNZ, $hippie-primary);

  @include feature-color(APAU, $apollo-primary);
  @include feature-color(APDE, $apollo-primary);
  @include feature-color(APFR, $apollo-primary);
  @include feature-color(APNZ, $apollo-primary);
  @include feature-color(APUK, $apollo-primary);
  @include feature-color(APIE, $apollo-primary);
  @include feature-color(APUS, $apollo-primary);

  @include feature-color(FFAU, $apollo-primary);
  @include feature-color(ZEAU, $apollo-primary);
  @include feature-color(FFNZ, $apollo-primary);
  @include feature-color(ZENZ, $apollo-primary);
  @include feature-color(FFUS, $apollo-primary);
  @include feature-color(ZEUS, $apollo-primary);

  @include feature-color(SRAU, $star-primary);
  @include feature-color(SRNZ, $star-primary);
  @include feature-color(SRUS, $star-primary);

  @include feature-color(BKGB, $cheapa-primary);
  @include feature-color(BKIE, $cheapa-primary);

  &__title {
    font: 600 1.2rem/1.8rem Mont;
    padding-right: 0.9rem;
  }
}
