@import 'variables';
@import 'brandColours';

.telephone {
  text-decoration: none;
  color: white;
}

.button {
  &-no-fill {
    color: color(apollo, logogreen);
    background: none;
    border: 1px solid color(apollo, logogreen);
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    font-weight: 600;
  }
}

.button-primary {
  margin-top: 2rem;
  background: $apollo-primary 0% 0% no-repeat padding-box;
  text-align: center;
  font: 700 1.6rem/2.4rem Mont;
  color: #ffffff;
  height: 5.2rem;
  padding: 1rem;
  box-shadow: 0rem 0.3rem 0.6rem #00000029;
  border-radius: 0.5rem;
}

.button-loading{
  margin-top: 2rem;
  text-align: center;
  font: 700 1.6rem/2.4rem Mont;
  color: #ffffff;
  height: 5.2rem;
  padding: 1rem;
  box-shadow: 0rem 0.3rem 0.6rem #00000029;
  border-radius: 0.5rem;

  background: grey 0 0 no-repeat padding-box;
}

.checkbox {
  font-size: 1.4rem;
  cursor: pointer;
  & > input {
    display: inline-block;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 3px;
    opacity: 1;
    cursor: pointer;
    height: 1.7rem;
    width: 1.7rem;
    vertical-align: middle;
  }

  & > label {
    display: inline-block;
    margin-left: 1.5rem;
    cursor: pointer;
  }
}

@mixin partner-style($partner-code, $primary-color, $primary-background) {
  .partner_#{$partner-code} {
    .button-primary {
      background: $primary-background 0% 0% no-repeat padding-box;
      color: $primary-color;
    }    
  }
}

@include partner-style(WWAPMHCO, #ffffff, $apollo-primary);

@include partner-style(WWCCMHCO, #ffffff, $cheapa-primary);
@include partner-style(WWCCMHNZ, #ffffff, $cheapa-primary);
@include partner-style(WWCCMHCA, #ffffff, $cheapa-primary);

@include partner-style(WWHIPCOM, #ffffff, $hippie-secondary);
@include partner-style(WWNZHIP, #ffffff, $hippie-secondary);

@include partner-style(STARRV, #ffffff, $star-primary);

@include partner-style(WWBUNK, #ffffff, $bunk-primary);

@include partner-style(RACQAFF, $racx-secondary, $racx-primary);
@include partner-style(RACV, $racx-secondary, $racx-primary);