$spinnerColorDark: #010076;
$spinnerColorLight: #ec5210;

.loading-pane {
  position: relative;

  &.lp-fill {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  > .lp-content,
  > .lp-spinner {
    transition: opacity 0.3s ease-in-out;
  }

  &.lp-loaded > .lp-content {
    transition: opacity 0.3s ease-in-out 0.5s;
  }

  > .lp-spinner {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    opacity: 0;
    z-index: 100;
  }
  > .lp-message {
    position: fixed;
    left: 0;
    top: 50%;
    z-index: 100;
    width: 100%;
    text-align: center;
    margin-top: 2.5rem;
    font-size: 1.5rem;
    opacity: 0;
    font-weight: bold;
  }
  > .lp-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.01;
    display: none;
    z-index: 99;
  }

  &.lp-loading > .lp-overlay {
    display: block;
  }

  &.lp-loading > .lp-spinner,
  &.lp-loading > .lp-message {
    display: block;
    opacity: 1;
  }

  &.lp-loaded > .lp-message,
  &.lp-loaded > .lp-spinner {
    display: none;
  }

  &.lp-loading > .lp-content,
  > .lp-content {
    opacity: 0.25;
  }

  &.lp-loaded > .lp-content {
    opacity: 1;
  }

  > .lp-spinner > .lp-spinner-icon {
    width: 40px;
    height: 40px;
    box-sizing: border-box;

    border: solid 4px transparent;

    border-radius: 50%;

    animation: lp-spinner 600ms linear infinite;
  }

  &.lp-light > .lp-spinner > .lp-spinner-icon {
    border-top-color: $spinnerColorLight;
    border-left-color: $spinnerColorLight;
  }

  &.lp-dark > .lp-spinner > .lp-spinner-icon {
    border-top-color: $spinnerColorDark;
    border-left-color: $spinnerColorDark;
  }

  &.lp-light > .lp-overlay {
    background-color: #000;
    opacity: 0.3;
  }
}

@keyframes lp-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.small-loading-pane {
  @extend .loading-pane;

  > .lp-spinner {
    position: absolute;
    left: 40%;
    top: 1rem;
    margin-left: 0px;
    margin-top: 0px;
  }

  > .lp-overlay {
    position: relative;
  }
}

