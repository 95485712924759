@import '../../styles/variables';
@import '../../styles/mixins';

.booking-search-filter {
  &__title {
    font: 700 1.8rem/2.2rem Mont;
    color: #636060;
  }

  &__group {
    margin-top: 2rem;
    font: 700 1.6rem Mont;
  }

  &__label {
    font: 400 1.4rem/2rem Mont;
  }

  &__checkbox {
    margin-left: 0px !important;
  }

  .checkbox {
    display: flex;
    margin-top: 1.5rem;
    @include sm {
      margin-right: 20px;
    }
    @include xxs {
      margin-right: 0px;
    }
  }

  &__checkbox-group {
    display: block;
    @include sm {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    @include xxs {
      display: block;
    }
  }

  .select-apollo {
    min-height: 5.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &__img {
      margin-right: 10px;
    }
    &__options {
      border: none;
      border-radius: 5px;
      font-family: Mont;
      width: auto;
      height: 30px;
      display: flex;
      &:focus {
        outline: 0;
      }
    }
    &__sleeps {
      font-size: 1rem;
    }
  }

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  width: 100%;
  padding: 2rem;
}
