.important-infos {
  margin: 20px 0;
}

.important-info {
  > p {
    margin-top: 1rem;
    font-size: 0.9em;
  }

  ul, ol {
    font-size: 0.9em;
    margin-left: 20px;
  }
}
